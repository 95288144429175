<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Genel Ayarlar</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    
    <div id="kt_account_profile_details" class="collapse show" v-if="settings">
        
      
      <!--begin::Form-->
      <Form id="kt_account_profile_details_form" class="form" @submit="saveChanges1()">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">

          <div class="row mb-6" v-for="(items, i) in first15settings" :key="i">            
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-lg-4 fv-row" v-for="(setting, j) in items" :key="j">
                            <label class="col-form-label">{{setting.label}}</label>
                            
                            <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="settings[i*3 + j].value"  :disabled="!setting.editable" />
                        </div>
                    </div>
              </div>
            </div>
          </div>

            <hr>
        
          <div class="row mb-6">
            
            <div class="col-lg-12 fv-row">
                <label class="col-lg-4 col-form-label fw-bold fs-6">Görüşme Sözleşmesi</label>
              <textarea class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" cols="30" rows="10" v-model="settings[15].value"></textarea>
            </div>
          </div>
            
            <hr>
        
            <div class="row mb-6">
            
            <div class="col-lg-12 fv-row">
                <label class="col-lg-4 col-form-label fw-bold fs-6">{{settings[16].label}}</label>
              <textarea class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" cols="30" rows="10" v-model="settings[16].value"></textarea>
            </div>
          </div>

          <hr>

           <div class="row mb-6">            
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-lg-4 fv-row" v-for="(setting, i) in settings.slice(17,20)" :key="i">
                            <label class="col-form-label">{{setting.label}}</label>
                            <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="settings[17+i].value" :disabled="!setting.editable" />
                        </div>
                    </div>
              </div>
            </div>
          </div>


                <hr>
          
             <div class="row mb-6">
            
            <div class="col-lg-12 fv-row">
                <label class="col-lg-4 col-form-label fw-bold fs-6">{{settings[20].label}}</label>
              <textarea class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" cols="30" rows="10" v-model="settings[20].value"></textarea>
            </div>
          </div>

              <hr>
          
             <div class="row mb-6">
            
            <div class="col-lg-12 fv-row">
                <label class="col-lg-4 col-form-label fw-bold fs-6">{{settings[21].label}}</label>
              <textarea class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" cols="30" rows="10" v-model="settings[21].value"></textarea>
            </div>
          </div>
         
        
         
         <hr>
         <div class="row mb-6">            
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-lg-4 fv-row" v-for="(setting, i) in settings.slice(22,24)" :key="i">
                            <label class="col-form-label">{{setting.label}}</label>
                            <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="settings[22+i].value" :disabled="!setting.editable" />
                        </div>
                    </div>
              </div>
            </div>
          </div>

          <hr>
         
          
         


        </div>
        <!--end::Card body-->

        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

          <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="updateSettings()" class="btn btn-primary">
            <span class="indicator-label"> Kaydet </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->

  


</template>



<script>
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default  {
  name:'AccountSettings',
  data(){
    return{
        
      first15settings:[],
      settings:null,

    }
  },

  mounted(){

     api.get('/System/ListSettings/M').then((res)=>{
      this.settings = res.data;
      let t =res.data.slice(0,15);
        this.first15settings[0]=t.slice(0,3);
        this.first15settings[1]=t.slice(3,6);
        this.first15settings[2]=t.slice(6,9);
        this.first15settings[3]=t.slice(9,12);
        this.first15settings[4]=t.slice(12,15);

        

    });
   
  },
  methods:{
    updateSettings () {
      const submitButton1 = this.$refs.submitButton1;
      
      submitButton1.setAttribute("data-kt-indicator", "on");
      console.log(this.settings);

       api.post('/System/UpdateListSettings',this.settings).then((res)=>{
          submitButton1.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Mobil Uygulama Ayarları Güncellendi.",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
      }).catch((err)=>{
          Swal.fire({
            text: "Bir hata oluştu",
            icon: "error",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-danger",
            },
          });
      });
      
    },

    
  }

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>